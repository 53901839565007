import { Tooltip, Modal } from "antd";
import "./index.css";
import React, { useEffect } from "react";
import { useTrialDays } from "../../hooks/useTrialDays";
import AltaButton from "../../components/AltaButton/AltaButton";
import useSignOut from "../../hooks/useSignOut";

const TrialDaysCounter = () => {
  const { warning } = Modal;

  const trialData = useTrialDays();
  const signOut = useSignOut();
  const latestSubscription = trialData ? trialData.latestSubscription : null;
  const is_trial = trialData ? trialData.is_trial : false;
  const remaining_days = is_trial ? trialData.remaining_days + 1 : null;
  const trial_ended = is_trial ? trialData.trial_ended : null;

  const showWarning = () => {
    warning({
      closable: true,
      maskClosable: false,
      closeIcon: <p>X</p>,
      title: latestSubscription
        ? "Su cuenta esta desactivada"
        : "Su trial demo ha finalizado",
      content: (
        <div>
          {latestSubscription
            ? "Reactiva tu cuenta y volve a disfrutar de todos los beneficios de nuestras calculadoras y herramientas!"
            : "Suscribite y disfruta de todos los beneficios de nuestras calculadoras y herramientas!"}

          <h5 style={{ marginTop: "10px", "font-weight": "normal" }}>
            * La suscripcion se renueva mes a mes de forma automática. Podes
            cancelar en cualquier momento.
          </h5>
        </div>
      ),
      onCancel() {
        signOut();
      },
      keyboard: false,
      okText: latestSubscription ? 'Reactiva tu cuenta' : 'Suscribirme',
      onOk: () => {
        window.location.href = latestSubscription
          ? "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=" +
          latestSubscription.subscription_plan.plan_id_reactivation
          : "https://www.liquidacionesjuridicas.com/wp-content/themes/businex/scripts/boton_suscribite_trial.php";
      }
    });
  };

  useEffect(() => {
    if (trial_ended) {
      setTimeout(() => showWarning(), 800);
    }
  }, [trial_ended]);

  return (
    is_trial &&
    !trial_ended && (
      <div>
        <Tooltip
          placement="right"
          title={`Restan ${remaining_days > 0 ? remaining_days : 0
            } días de trial`}
        >
          <AltaButton extra={remaining_days > 0 ? remaining_days : 0} />
        </Tooltip>
      </div>
    )
  );
};

export default TrialDaysCounter;
